import { request } from '@/util/request'

export function getSurveyInfo(params) {
  return request({
    url: '/api/survey/GetSurveyInfo',
    method: 'get',
    params,
  })
}

export function submitSurvey(data) {
  return request({
    url: '/api/survey/SubmitSurvey',
    method: 'post',
    data,
  })
}
