<template>
  <div>
    <div id="pageDiv" style="display: block">
      <div id="answerProcess" style="">
        <div class="processInner mainBgColor"></div>
      </div>
      <div id="headsplitdiv"></div>
      <form id="form1">
        <div class="vote-header">
          <div id="toptitle">
            <h1 id="htitle" class="htitle">{{ surveyInfo.title }}</h1>
          </div>
        </div>
        <div id="divBackgroundWrap">
          <div id="bgimgwrap" class="backgroundimgWrap">
            <img
              alt=""
              class="backgroundimg"
              src="@/assets/survey_images/background.png"
            />
            <div class="backgroundshade"></div>
          </div>
        </div>

        <div id="divContent" class="divContent" style="">
          <div id="divDesc" class="formfield">
            <span class="description" style="white-space: pre-wrap;">
              {{ surveyInfo.des }}
            </span>
          </div>
          <div id="divQuestion">
            <fieldset id="fieldset1" class="fieldset" pg="1" style="">
              <div class="field ui-field-contain">
                <div v-for="(item, index) in list" :key="'index' + index">
                  <div :id="'question' + index" class="field-label">
                    {{ index + 1 }}、{{ item.name }}
                    <span v-show="item.required === 1" class="req">*</span>
                  </div>
                  <div v-if="item.type === 1" class="item">
                    <van-radio-group
                      v-for="(itemOptions, indexOptions) in item.list"
                      :key="'indexOptionsRadio' + indexOptions"
                      v-model="item.value"
                      style="margin-bottom: 10px"
                    >
                      <van-radio :name="itemOptions.value">
                        {{ itemOptions.value }}
                      </van-radio>
                    </van-radio-group>
                    <template v-if="item.children.length > 0">
                      <div
                        v-for="(itemChild, indexChild) in item.children"
                        :key="'indexChild' + indexChild"
                      >
                        <template
                          v-if="
                            itemChild.control === 0 ||
                              itemChild.condition === item.value
                          "
                        >
                          <div
                            :id="'question' + index + '-' + indexChild"
                            class="field-label"
                          >
                            {{ index + 1 }}.{{ indexChild + 1 }}、{{
                              itemChild.name
                            }}
                            <span v-show="itemChild.required === 1" class="req">
                              *
                            </span>
                          </div>
                          <template v-if="itemChild.type === 1">
                            <van-radio-group
                              v-for="(itemChildOptions,
                              indexChildOptions) in itemChild.list"
                              :key="
                                'indexChildOptionsRadio' + indexChildOptions
                              "
                              v-model="itemChild.value"
                              style="margin-bottom: 10px"
                            >
                              <van-radio :name="itemChildOptions.value">
                                {{ itemChildOptions.value }}
                              </van-radio>
                            </van-radio-group>
                          </template>
                          <template v-if="itemChild.type === 2">
                            <van-checkbox-group
                              v-for="(itemChildOptions,
                              indexChildOptions) in itemChild.list"
                              :key="
                                'indexChildOptionsCheckbox' + indexChildOptions
                              "
                              v-model="itemChild.valueArr"
                              style="margin-bottom: 10px"
                            >
                              <van-checkbox
                                :name="itemChildOptions.value"
                                shape="square"
                              >
                                {{ itemChildOptions.value }}
                              </van-checkbox>
                            </van-checkbox-group>
                          </template>
                          <template v-if="itemChild.type === 3">
                            <van-field
                              v-model="itemChild.value"
                              rows="4"
                              autosize
                              label=""
                              type="textarea"
                              placeholder="请输入"
                            />
                          </template>
                        </template>
                      </div>
                    </template>
                  </div>
                  <div v-if="item.type === 2" class="item">
                    <van-checkbox-group
                      v-for="(itemOptions, indexOptions) in item.list"
                      :key="'indexOptionsCheckBox' + indexOptions"
                      v-model="item.valueArr"
                    >
                      <van-checkbox
                        :name="itemOptions.value"
                        shape="square"
                        style="margin-bottom: 10px"
                      >
                        {{ itemOptions.value }}
                      </van-checkbox>
                    </van-checkbox-group>
                  </div>
                  <div v-if="item.type === 3" class="item">
                    <van-field
                      v-model="item.value"
                      class="rowTextArea"
                      label=""
                      type="textarea"
                      placeholder="请输入"
                      rows="4"
                      border
                    />
                  </div>
                  <van-divider />
                </div>
              </div>
            </fieldset>
          </div>

          <div class="footer">
            <div id="divSubmit" style="padding: 10px 20px; z-index: 0">
              <div class="voteDiv">
                <div>
                  <van-button
                    id="ctlNext"
                    class="submitbtn mainBgColor"
                    @click.prevent="handleSubmit"
                  >
                    提交答卷
                  </van-button>
                  <a
                    class="button white ctBack"
                    href="javascript:;"
                    style="display: none"
                  >
                    返回
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="reportfooter" class="logofooter bottomDiv">
          <div id="divPowerBy" style="margin: 0px auto">
            <div class="wjfooter">
              <span id="spanPower">
                宁波市威尔信息科技有限公司
                <span>提供技术支持</span>
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import { Dialog, Toast } from 'vant'
  import { checkSurveyAnswer } from '@/api/survey/answer/answer'
  import { getSurveyInfo, submitSurvey } from '@/api/survey/survey'

  export default {
    name: 'Question',
    data() {
      return {
        list: [],
        loading: false,
        element: '', //DOM元素ID定位
        search: {
          id: 0,
          openId: '',
          accountId: '',
          name: '',
          number: '', //his传过来的编号
          type: '', //his传过来的类型
          questionList: [],
        },
        surveyInfo: {},
      }
    },
    created() {
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      this.search.openId = userInfo.openID
      this.search.accountId = userInfo.id
      this.search.name = userInfo.name
      this.search.type = Number(this.$route.query.type)
      this.checkSurvey()
    },
    methods: {
      //判断当前问卷是否提交
      async checkSurvey() {
        const { success, msg } = await checkSurveyAnswer({
          OpenId: this.search.openId,
          Type: this.search.type,
        })
        if (success) {
          Dialog.alert({
            title: '提示',
            message: msg,
          }).then(() => {
            wx.closeWindow()
          })
        } else {
          await this.fetchData()
        }
      },

      //必填项验证
      validate(list, value, index) {
        let flag = true
        this.element = ''
        for (let i = 0; i < list.length; i++) {
          if (!flag) break
          let item = list[i]
          if (
            item.required === 1 &&
            (item.control === 0 || item.condition === value)
          )
            switch (item.type) {
              case 1:
              case 3:
                if (item.value === '' || item.value == null) {
                  flag = false
                  this.element =
                    index === -1 ? 'question' + i : 'question' + index + '-' + i
                }
                break
              case 2:
                if (
                  item.valueArr == '' ||
                  item.valueArr == null ||
                  item.valueArr.length === 0
                ) {
                  flag = false
                  this.element =
                    index === -1 ? 'question' + i : 'question' + index + '-' + i
                }
                break
            }
          if (item.children.length > 0 && flag === true) {
            flag = this.validate(item.children, item.value, i)
          }
        }
        return flag
      },

      //提交问卷
      async handleSubmit() {
        if (!this.validate(this.list, 0, -1)) {
          Dialog.alert({
            message: '请完成必填选项后再提交问卷！',
          }).then(() =>
            document.getElementById(this.element).scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            })
          )
          return false
        }

        this.list.forEach((item) => {
          if (item.type === 2) {
            if (
              item.valueArr !== '' &&
              item.valueArr !== null &&
              item.valueArr != undefined
            ) {
              item.value = item.valueArr.join('|')
            }
          }
          if (item.children.length > 0) {
            item.children.forEach((itemChild) => {
              if (itemChild.type === 2) {
                if (
                  itemChild.valueArr !== '' &&
                  itemChild.valueArr !== null &&
                  itemChild.valueArr != undefined
                ) {
                  itemChild.value = itemChild.valueArr.join('|')
                }
              }
            })
          }
        })
        this.search.questionList = this.list

        Dialog.confirm({
          title: '确认',
          message: '您确定要提交当前问卷吗？',
        }).then(async () => {
          const { msg } = await submitSurvey(this.search)
          Dialog.alert({
            message: msg,
          }).then(() => {
            wx.closeWindow()
          })
        })
      },

      //获取方法
      async fetchData() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getSurveyInfo({ Type: this.search.type })
        this.surveyInfo = data
        this.search.id = data.id
        this.list = data.questionList
        Toast.clear()
      },
    },
  }
</script>
<style lang="scss" scoped>
  .rowTextArea::v-deep .van-field__value {
    border: 1px solid #1691fe;
    border-radius: 5px;
  }
  .van-radio,
  .van-checkbox {
    font-size: 16px;
    word-break: break-all;
  }
  .van-cell {
    padding: 0;
  }

  .hasbgpic #form1 {
    background: #fff;
  }

  body {
    margin: 0px;
    text-align: left;
  }

  #headsplitdiv {
    height: 20px !important;
  }

  div.field {
    border: 0px;
  }

  #divAwardAfter {
    margin-top: 0px !important;
  }

  #form1 {
    margin: 0px 14px;
  }

  #form1,
  #divWorkError {
    max-width: 800px;
    background: #fff;
    margin: 0 auto !important;
  }

  .landscape #form1 {
    margin: 0 35px;
  }

  .matrixtable {
    background: #fff;
  }

  #divTip {
    width: 100%;
    font-size: 14px;
    color: #ff5760;
    text-align: center;
    background: #ffeaea;
  }

  #divPromote {
    margin-top: 0px !important;
  }

  body #divBackgroundWrap {
    position: fixed;
    /*position:absolute\0;*/
    top: 0px;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: -5;
    background-image: url('//image.wjx.cn/images/theme/vmbg1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
  }

  body #divBackgroundWrap {
    background-image: none;
    background-color: #ecfaec;
  }
  .backgroundshade {
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ecfaec 100%
    );
  }

  /*HJJ-新增*/
  input,
  textarea {
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 9px;
  }
  .ui-radio label {
    margin-left: 0px !important;
    padding-left: 10px !important;
  }

  /*引入改复制*/
  #answerProcess {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 101;
  }

  .processInner {
    height: 2px;
    background: #0095ff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  #toplogo {
    overflow: hidden;
    padding: 0;
  }

  .pclogo {
    display: none;
  }

  .pclogo {
    display: block;
  }

  #toptitle {
    background: #fff;
  }

  #toptitle {
    padding: 40px 0px 12px;
    width: 100%;
    color: #0095ff;
  }

  #toptitle .htitle {
    line-height: 40px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
  }
  #toptitle .htitle {
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .Verify_wrap #toptitle {
    padding: 0;
    font-size: 18px;
  }

  .backgroundimgWrap {
    width: 100%;
    position: relative;
    z-index: -3;
  }

  .backgroundimg {
    width: 100%;
    position: relative;
    z-index: -2;
  }

  #divContent {
    padding-bottom: 20px;
  }

  #divContent {
    background-color: #fff;
  }

  div.formfield {
    display: block;
    padding: 10px 0 26px;
    margin: 0 20px 16px;
    clear: both;
    border-bottom: 1px dashed #ccc;
  }

  span.req {
    position: absolute;
    top: 0;
    left: -12px;
    color: #ff4040 !important;
    margin: 2px 0 0 2px;
  }

  span.description {
    font-size: 15px;
    line-height: 24px;
  }

  fieldset {
    border: 0;
    min-inline-size: unset;
  }

  .cutfield {
    padding: 8px 20px;
    -webkit-font-smoothing: antialiased;
  }

  div.field {
    clear: both;
    margin: 5px 0;
    border: 1px solid transparent;
    .item {
      background: #f9f9ff;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
    }
  }

  div.field {
    margin: 0 0 10px 0;
  }

  .field .select_title:nth-of-type(2) {
    margin: 0;
  }

  .landscape div.field {
    padding: 10px 20px;
  }

  .field > .ui-select,
  .field > .ui-input-text {
    width: 400px;
  }

  div.field-label {
    position: relative;
    font-size: 17px !important;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    padding: 0 0 10px 0 !important;
    display: block;
    word-wrap: break-word;
    word-break: break-all;
    line-height: 26px;
    color: #262626;
  }

  .field-label img {
    vertical-align: top;
  }

  div.field-label {
    font-size: 17px !important;
    line-height: 26px;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
  }

  .ui-controlgroup {
    padding: 0;
    border: 1px solid #e3e3e3;
    border-bottom: 0;
    border-right: 0;
  }

  .ui-controlgroup .ui-radio,
  .ui-controlgroup .ui-checkbox,
  .ui-controlgroup .ui-li-static {
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
    min-height: 48px;
    border-bottom: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    background-color: #fff;
    position: relative;
    flex: 1 1 auto;
  }

  .ui-controlgroup .ui-radio .label,
  .ui-controlgroup .ui-checkbox .label,
  .ui-controlgroup .ui-radio label,
  .ui-controlgroup .ui-checkbox label {
    display: block;
    margin-left: 40px;
    cursor: pointer;
    font-size: 16px;
    color: #595959;
    text-align: left;
    padding: 13px 10px 13px 0;
    line-height: 22px;
    min-height: 36px;
  }

  .ui-controlgroup .ui-radio .hasImagelabel,
  .ui-controlgroup .ui-checkbox .hasImagelabel {
    margin-left: 0 !important;
    padding: 0 !important;
    position: relative;
    text-align: center;
  }

  .ui-controlgroup .divlabel {
    border-right: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    background: #f9f9f9;
  }

  .ui-controlgroup .ui-radio.checked .jqchecked:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #8c8c8c;
    border-radius: 50%;
    animation: antCheckboxEffect 0.4s ease-in-out;
    animation-fill-mode: both;
  }

  .ui-controlgroup .ui-checkbox.checked .jqchecked:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #8c8c8c;
    border-radius: 3px;
    animation: antCheckboxEffect2 0.36s ease-in-out;
    animation-fill-mode: both;
  }

  .ui-controlgroup .divlabel {
    border: 0;
  }

  .ui-controlgroup {
    border: 0;
    margin: 0;
  }

  .ui-controlgroup .ui-radio,
  .ui-controlgroup .ui-checkbox,
  .ui-controlgroup .ui-li-static {
    border: 0;
    min-height: 38px;
    flex: 0 0 auto;
  }

  .ui-controlgroup .ui-radio .label,
  .ui-controlgroup .ui-checkbox .label,
  .ui-controlgroup .ui-radio label,
  .ui-controlgroup .ui-checkbox label {
    padding: 8px 10px 8px 0;
    margin-left: 36px;
  }

  .ui-controlgroup .ui-radio .hasImagelabel:hover .popimg_eye,
  .ui-controlgroup .ui-checkbox .hasImagelabel:hover .popimg_eye {
    width: 100%;
    height: 200px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background: 0;
  }

  .ui-controlgroup .ui-radio .hasImagelabel:hover .popimg_eye img,
  .ui-controlgroup .ui-checkbox .hasImagelabel:hover .popimg_eye img {
    width: 32px;
    height: 32px;
  }

  #divMultiPage,
  #divSubmit {
    position: relative;
    z-index: 101;
  }

  #divProgress,
  #divMultiPage,
  #divSubmit {
    padding: 10px 20px !important;
  }

  #ctlNext {
    width: 100%;
    display: block;
    margin: 0 auto;
    outline: 0;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    height: 44px;
    font-weight: 500;
    border-radius: 2px;
    color: #fff;
    background: #0095ff;
  }

  .landscape #ctlNext {
    width: 200px;
  }

  #ctlNext {
    width: 180px;
  }

  a,
  button,
  label,
  textarea,
  div,
  select,
  option {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: 0;
  }

  .button {
    width: 100%;
    display: block;
    margin: 10px 0;
    outline: 0;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    font-size: 16px;
    border-radius: 2px;
    height: 44px;
    line-height: 42px;
  }

  .button:hover {
    text-decoration: none;
  }

  .button:active {
    position: relative;
    top: 1px;
  }

  .white {
    background: #fff;
    color: #262626;
    border: 1px solid #e3e3e3;
  }

  #hrefPreview,
  .ctBack {
    width: 180px;
    margin: 10px auto;
  }

  #reportfooter {
    position: relative !important;
  }

  .logofooter {
    width: 100%;
    z-index: 100;
  }

  .logofooter #spanPower > a {
    position: relative;
    color: #8c8c8c;
    font-size: 14px;
    text-decoration: none;
    padding-left: 4px;
    vertical-align: middle;
    margin-right: 6px;
  }

  .logofooter #spanPower i {
    vertical-align: middle;
    color: #8c8c8c;
  }

  .logofooter #spanPower span {
    color: #bebebe;
    vertical-align: middle;
  }

  .bottomDiv {
    position: relative;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }

  #divPowerBy {
    background: #f9f9f9;
  }

  #divPowerBy {
    background: rgba(0, 0, 0, 0.03);
  }

  .wjfooter {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #bebebe;
    font-size: 14px;
  }

  .wjfooter .spanfoottext {
    font-size: 12px;
    margin-bottom: 2px;
    color: gray;
  }
</style>
